import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// wow.js动画效果
import "animate.css"
import "wowjs/css/libs/animate.css"// 巨坑... 此地方要引入两个css
import wow from 'wowjs'
Vue.prototype.$wow = wow;
// 动态展示数字
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber);

Vue.config.productionTip = false;
//懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload);
//调用全局api
import api from './api'
Vue.prototype.api = api;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
