<template>
    <div class="header">
        <div class="title">
            <div class="logo">
                <a href="https://www.yells.com.cn/#/home" target="_blank">
                    <img src="../assets/publicImg/logo.svg" alt="">
                </a>
            </div>
            <div class="content">
                <div class="btnParent">
                    <div class="btn" v-for="(item, index) in router" :key="index">
                        <div @click="setUrlFn(item.link, index)">
                            <p  :class="{ clickBtn: selectedIndex === index }">{{item.title}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "titleCom",
        data() { //初始数据
            return {
                router: [
                    {
                        title: "首页",
                        link: '/home'
                    },
                    {
                        title: "关于研视",
                        link: '/aboutYells'
                    },
                    {
                        title: "解决方案",
                        link: '/solution'
                    },
                    {
                        title: "行业优势",
                        link: '/advantage'
                    },
                    {
                        title: "资讯",
                        link: '/information'
                    },
                    {
                        title: "加入我们",
                        link: '/recruit'
                    }
                ],
                selectedIndex: null
            }
        },
        watch: { //监听
            '$route'(newRoute) {
                this.setRouter(this.getTrimmedPath(newRoute.path));
            },
        },
        methods: { //函数
            setUrlFn(url, index) {
                this.selectedIndex = index;
                this.$router.push(url)
            },
            setRouter(val) {
                this.router.forEach((item, index) => {
                    if (item.link === val) {
                        this.setUrlFn(val, index)
                    }
                });
            },
            getTrimmedPath(path) {
                const secondSlashIndex = path.indexOf('/', 1); // 查找第二个斜杠的位置（从索引为1开始）
                if (secondSlashIndex !== -1) {
                    return path.substring(0, secondSlashIndex); // 截取需要的部分
                }
                return path;
            }
        },
        mounted() {
            this.setRouter(this.$route.path)
        }
    }
</script>

<style scoped lang="scss">
    .header{
        width: 100vw;
        background-color: rgba(255, 255, 255, 0.6);
    }
    .title{
        width: 54.73vw;
        max-width: 1166px;
        min-width: 545px;
        height: 60px;
        margin: auto;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo{
        width: 14.375vw;
        max-width: 276px;
        min-width: 187px;
        display: flex;
        align-items: center;
        img{
            margin: auto;
            height: 18px;
            cursor: pointer;
        }
    }
    .content{
        display: flex;
        align-items: center;
        height: 60px;
        justify-content: space-between;
        .btnParent{
            width: 46.4vw;
            min-width: 360px;
            max-width: 891px;
            height: 60px;
            display: flex;
            justify-content: space-evenly;
        }
        .btn{
            display: flex;
            p{
                cursor: pointer;
                height: 41px;
                padding: 0 10px;
                line-height: 62px;
                color: #2C333A;
                text-align: center;
                font-family: "Noto Sans SC Variables", sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                border-bottom: 2px solid transparent;
                &:hover{
                    /*border-radius: 5px;*/
                    /*background: rgba(9, 30, 66, 0.10);*/
                    color: #388BFF;
                }
            }
            .clickBtn{
                color: #161A1D;
                height: 58px;
                line-height: 62px;
                border-bottom: 2px solid #161A1D;
                &:hover{
                    color: #2C333A;
                }
            }
        }
    }
</style>