import Vue from 'vue'
import VueRouter from 'vue-router'
import homeView from '../views/homeView.vue'
Vue.use(VueRouter);
// 解决点击相同路由报错
const RouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
    return RouterPush.call(this, to).catch((err) => err)
};
const RouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
    return RouterReplace.call(this, to).catch((err) => err)
};
const routes = [
    {
        path: '/',
        redirect:'/home',
    },
    {
        path: '/home',
        component: homeView,
        children:[
            {
                path: '',
                component: () => import("../views/home/homePageView.vue"),
            },
            {
                path: "/solution/:id?", //解决方案
                props:true,
                component: () => import("../views/home/solutionView.vue")
            },
            {
                path: "/advantage", //行业优势
                component: () => import("../views/home/advantageView.vue")
            },
            {
                path: "/information/:id?", //资讯
                component: () => import("../views/home/informationView.vue")
            },
            {
                path: "/recruit", //招聘
                component: () => import("../views/home/recruitView.vue")
            },
            {
                path: "/aboutYells/:action?", //关于研视
                props:true,
                component: () => import("../views/home/aboutYellsView.vue")
            }
        ]
    },
    {
        path: '*',
        component: () => import("../views/errorView.vue")
    }
];

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  //base: process.env.BASE_URL,
  routes
});

export default router
