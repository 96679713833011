<template>
    <div>
        <div class="headerComDiv">
            <header-com></header-com>
        </div>
        <div style="margin-top: 60px">
            <router-view></router-view>
        </div>
        <bottom-com></bottom-com>
    </div>
</template>
<script>
    import headerCom from '../components/headerCom.vue'
    import bottomCom from '../components/bottomCom.vue'
    export default {
        name: "home",
        components: { //组件
            headerCom, bottomCom
        },
        data() { //初始数据
            return {}
        },
        computed: { //计算: data中衍生出来的数据,不可以更改,会自动更新,挂载在THIS下
        },
        watch: { //监听

        },
        methods: { //函数
        },
        created() { //实例加载完成

        },
        mounted() { //dom加载完成后执行

        }
    }
</script>

<style scoped lang="scss">
    .headerComDiv{
        position: fixed;
        top: 0;
        z-index: 9;
    }

</style>
